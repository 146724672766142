import { _apiGetCalls } from "./ApiCalls";
import { _GetBlogs } from "./BlogManager";

export const _getSiteData = async (dispatch, token) => {
  try {
    const request = await _apiGetCalls("/admin/auth", token);
    console.log(request);
    dispatch({
      type: "admindata",
      payload: {
        mentors: request.mentors,
        mentorSession: request.sessions,
        transcation: request.transcation,
        user: request.user,
        jobs: request.jobs,
        products: request.products,
      },
    });
    _GetBlogs(dispatch);
  } catch (err) {
    console.log(err);
  }
};
