export const initialstate = {
  user: null,
  isFetchingData: false,
  Products: [],
  Blogs: [],
  mentors: [],
  userSessions: [],
  hasActivePack: false,
  ActivePack: [],
  UserTranscation: [],
  otherInfo: [],
  jobs: [],
  verifyCode: false,
  error: {
    error: false,
  },
  isLoading: false,
  hasAccount: true,
  OnboardingStep: 0,
  adminData: [],
  userFeedback: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "currentUser":
      return {
        ...state,
        user: action.payload,
      };
    case "admindata":
      return {
        ...state,
        adminData: action.payload,
      };
    case "verifyCode":
      return {
        ...state,
        verifyCode: action.payload,
      };
    case "mentors":
      return {
        ...state,
        mentors: action.payload,
      };
    case "room_feedback":
      return {
        ...state,
        userFeedback: action.payload,
      };
    case "user_Sessions":
      return {
        ...state,
        userSessions: action.payload,
      };
    case "hasUserAccount":
      return {
        ...state,
        hasAccount: action.payload,
      };
    case "isFetchingData":
      return {
        ...state,
        isFetchingData: action.payload,
      };
    case "site_Products":
      return {
        ...state,
        Products: action.payload,
      };
    case "site_jobs":
      return {
        ...state,
        jobs: action.payload,
      };

    case "site_Blogs":
      return {
        ...state,
        Blogs: action.payload,
      };
    case "site_Errors":
      return {
        ...state,
        error: action.payload,
      };
    case "site_Loading":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "user_transcations":
      return {
        ...state,
        UserTranscation: action.payload,
      };
    case "hasActivePack":
      return {
        ...state,
        hasActivePack: action.payload,
      };
    case "Active_pack":
      return {
        ...state,
        ActivePack: action.payload,
      };
    case "OnboardingStep":
      return {
        ...state,
        OnboardingStep: action.payload,
      };
    case "otherInfo":
      return {
        ...state,
        otherInfo: action.payload,
      };
    default:
      return state;
  }
};
