import axios from "axios";

export const _GetBlogs = (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.get(
        "https://www.skillkart.app/api/blog/Blog.php"
      );
      dispatch({
        type: "site_Blogs",
        payload: result.data.data,
      });
      console.log(result);
      resolve(result.data.data);
    } catch (err) {
      reject(err);
    }
  });
};
