import { lazy } from "react";

export const AdminAuth = lazy(() => import("../Pages/Auth/Login"));
export const AdminMentor = lazy(() =>
  import("../Pages/Dashboard/Mentor/Mentor")
);
export const AdminStudent = lazy(() =>
  import("../Pages/Dashboard/Student/Student")
);
export const AdminProduct = lazy(() =>
  import("../Pages/Dashboard/Product/Products")
);

export const AdminJobs = lazy(() => import("../Pages/Dashboard/Jobs/Jobs"));
export const Blogs = lazy(() => import("../Pages/Dashboard/Blog/Blog"));
