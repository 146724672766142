import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  AdminAuth,
  AdminJobs,
  AdminMentor,
  AdminProduct,
  AdminStudent,
  Blogs,
} from "./Links";
function PageRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact path="/hq/jobs" element={<AdminJobs />} />
        <Route exact path="/hq/blogs" element={<Blogs />} />
        <Route exact path="/hq/mentor" element={<AdminMentor />} />
        <Route exact path="/hq/product" element={<AdminProduct />} />
        <Route exact path="/hq/student" element={<AdminStudent />} />
        <Route exact path="/" element={<AdminAuth />} />
      </Routes>
    </Router>
  );
}

export default PageRoutes;
