import logo from "./logo.svg";
import "./App.css";
import React, { useEffect } from "react";
import PageRoutes from "./Routes/Routes";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { _getSiteData } from "./Services/OtherManager";

function App() {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();
  useEffect(() => {
    console.log(cookies.jwt);
    _getSiteData(dispatch, cookies.jwt);
  }, []);
  return <PageRoutes />;
}

export default App;
